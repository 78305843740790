import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql } from "gatsby"
import React from "react"
import GA from "../components/ga"
import InnerWrapper from "../components/innerWrapper"
import Layout from "../components/layout"
import SEO from "../SEO"
import { slugify } from "../util/utilityFunctions"
import styles from "./scss/work.module.scss"


export const query = graphql`
  query($title: String!) {
    contentfulWork(title: { eq: $title }) {
      title
      company
      link
      image {
        title
        description
        fluid(quality: 100, maxWidth: 1920) {
          src
          srcWebp
        }
      }
      body {
        json
      }
    }
  }
`

class Work extends React.Component {
  render() {
    const options = {
      renderNode: {
        "embedded-asset-block": node => {
          const alt = node.data.target.fields.title["en-US"]
          const url = node.data.target.fields.file["en-US"].url

          const width =
            node.data.target.fields.file["en-US"].details.image.width

          return (
            <img className={styles.image} alt={alt} src={url} width={width} />
          )
        },
      },
    }

    return (
      <>
        <GA />
        <Layout>
          <SEO
            title={`Work | ${this.props.data.contentfulWork.title}`}
            pathname={`/work/${slugify(this.props.data.contentfulWork.title)}`}
          />
          <section className={styles.work}>
            <div className={styles.hero}>
              <picture>
                <source
                  srcSet={this.props.data.contentfulWork.image.fluid.srcWebp}
                  type="image/webp"
                />
                <source
                  srcSet={this.props.data.contentfulWork.image.fluid.src}
                  type="image/png"
                />
                <img
                  className={styles.heroImage}
                  src={this.props.data.contentfulWork.image.fluid.src}
                  alt={this.props.data.contentfulWork.image.description}
                />
              </picture>
            </div>
            <InnerWrapper width={800}>
              <h1>{this.props.data.contentfulWork.title}</h1>
              <div>
                {documentToReactComponents(
                  this.props.data.contentfulWork.body.json,
                  options
                )}
              </div>
            </InnerWrapper>
          </section>
        </Layout>

      </>
    )
  }
}
export default Work
